@import './Global.scss';

// Importing Aktiv Gro tesk font-family
@import url('../fonts/AktivGrotesk/stylesheet.css');


body {
  /* width */
  &::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f6f6f6;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: gray;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.switch-wrapper {
  position: relative;
}

.highlight {
  &:hover {
    font-weight: bold;
    color: var(--color-orange) !important;
  }
}

.brainiac-modal,
.collateral-confirm-modal {
  .ant-modal-content {
    border-radius: 20px;
    background-color: var(--color-bg-primary);
    .ant-modal-body {
      padding: 0;
      border-radius: 20px;
    }
  }
}

.scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 7px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    background-color: var(--color-primary);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e53d52;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.ant-select-dropdown-menu-item {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: var(--color-text-main);

  &:hover {
    background-color: var(--color-bg-active) !important;
  }
}

.ant-select-dropdown-menu-item-selected {
  color: var(--color-text-main);
  font-weight: 600;
  background-color: var(--color-bg-primary) !important;
}

input {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    padding-right: 22px;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    padding-right: 22px;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    padding-right: 22px;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    padding-right: 22px;
  }

  /* Hide arrows from input number */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.proposal-detail {
  table {
    border: 1px solid #ccc;
  }

  td,
  th {
    border-top-width: 1px;
    border-top-color: #ccc;

    /* double, dashed, dotted... */
    border-top-style: solid;
    border-right-width: 1px;

    /* double, dashed, dotted... */
    border-right-style: solid;
    border-right-color: #ccc;
  }

  a {
    color: #09d395;
  }
}

/* Common CSS */

.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.just-center {
  justify-content: center;
}
.just-between {
  justify-content: space-between;
}
.just-around {
  justify-content: space-around;
}
.just-end {
  justify-content: flex-end;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.pointer {
  cursor: pointer;
}
.full {
  width: 100%;
}

.ant-btn {
  border: none;
  background-color: var(--color-yellow);
  color: white;

  &:hover,
  &:focus {
    background-color: var(--color-gold);
    color: white;
  }
}

h1,
h2,
h3,
h4 {
  color: inherit;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

h4 {
  font-size: 1.25rem;
  font-weight: 600;
}

p {
  color: var(--color-text-secondary);
}

a {
  color: var(--color-text-secondary);

  &:hover {
    color: var(--color-text-main);
  }

  &:active {
    color: var(--color-text-inactive);
  }
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--twitter {
  background-color: #55acee
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9
}

.resp-sharing-button--pinterest {
  background-color: #bd081c
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615
}

.resp-sharing-button--facebook {
  background-color: #3b5998
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373
}

.resp-sharing-button--tumblr {
  background-color: #35465C
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c
}

.resp-sharing-button--reddit {
  background-color: #5f99cf
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1
}

.resp-sharing-button--google {
  background-color: #dd4b39
}

.resp-sharing-button--google:hover {
  background-color: #c23321
}

.resp-sharing-button--linkedin {
  background-color: #0077b5
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293
}

.resp-sharing-button--email {
  background-color: #777
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e
}

.resp-sharing-button--xing {
  background-color: #1a7576
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c
}

.resp-sharing-button--whatsapp {
  background-color: #25D366
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851
}

.resp-sharing-button--hackernews {
background-color: #FF6600
}
.resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }

.resp-sharing-button--vk {
  background-color: #507299
}

.resp-sharing-button--vk:hover {
  background-color: #43648c
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
  border-color: #5f99cf;
}

.resp-sharing-button--reddit:hover,
.resp-sharing-button--reddit:active {
  background-color: #3a80c1;
  border-color: #3a80c1;
}

.resp-sharing-button--telegram {
  background-color: #54A9EB;
}

.resp-sharing-button--telegram:hover {
  background-color: #4B97D1;}
