//   Importing variable and mixin scss file
@import './MixinAndVariables.scss';

.lend {
  position: relative;
  top: 0px;
  width: 100%;
  padding-top: 4.375em;
  background: #121212;
}

.lend__container {
  position: relative;
  top: 0px;
  width: 100%;
  color: #ffffff;
  font-family: 'Aktiv Grotesk Corp';
}

.lend__head {
  position: relative;
  top: 0px;
  width: 100%;
}

.lend__heading {
  width: 100%;
  text-align: center;
  position: relative;
  top: 0px;
  padding-left: 10%;
  padding-right: 10%;

  h1 {
    font-weight: bold;
    font-size: 3em;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
  }
  h5 {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
}

.lend__meters-container {
  position: relative;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.lend__meters {
  @include flexProp;
  width: 13em;
  background: url('../../homeImages/lend-meters-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  h6 {
    margin-top: 16px;
    font-weight: 400;
    font-size: 1em;
    color: #ffffff;
  }
}

.lend__meter-values {
  @include flexProp;
  width: 4em;
  height: 4em;
  margin-top: 60px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  backdrop-filter: blur(17px);
  border-radius: 50%;
  font-weight: 400;
  font-size: 2.25em;
  color: #ffffff;
  box-shadow: -3px -3px 3px rgba(255, 255, 255, 0.21);
}

// Key Points Styling
.lend__key-points-container {
  position: relative;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5.5em;
}

.lend__key-points-inner-container {
  position: relative;
  left: -9%;
  width: 23%;
}

.lend__key-point-image {
  @include flexProp;
  position: relative;
  top: 0px;
  left: -6%;
  width: 40%;
  height: 34em;
  background-image: url('../../homeImages/lend-key-point-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  img:nth-child(1) {
    position: relative;
    z-index: 2;
    width: 37%;
    min-width: 157px;
    height: auto;
    animation: rotateBall 100s linear infinite;
  }

  @keyframes rotateBall {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  img:nth-child(2) {
    position: absolute;
    top: 54%;
    right: 31%;
    content: ' ';
    width: 17%;
    height: auto;
    background: radial-gradient(81.25% 81.25% at 67.32% 18.75%, #bceeeb 0%, #5378d9d6 100%);
    border-radius: 50%;
    min-width: unset;
    z-index: 1;
  }
}
.lend__each-key-points-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4em;
  min-width: 20em;

  h5 {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
}

.lend__key-points-icon-box {
  @include flexProp;
  background: #0e0e0e;
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.02), 6px 8px 31px rgba(0, 0, 0, 0.25),
    inset 0px -4px 11px rgba(0, 0, 0, 0.7), inset 0px 4px 8px rgba(255, 255, 255, 0.18);
  border-radius: 50%;
  margin-right: 10px;
  img {
    width: 1.31em;
    height: 1.31em;
    margin: 0.93em;
  }
}

.lend__deposit-button {
  @include flexProp;
  position: relative;
  top: 0px;
  width: 100%;
  a {
    @include flexProp;
    button {
      @include ButtonProp;
      padding: 0.6em 2em 0.6em 2em;
      border-radius: 0.5em;
      border: 0.125em solid transparent;
    }
    margin-bottom: 2.562em;
  }
}

/* Styling for small screen sizes   */
@media (min-width: 1408px) {
  .lend__key-point-image {
    height: 49em;
  }
}

@media (max-width: 931px) {
  .lend__heading h1 {
    font-size: 1.75em;
  }

  .lend__heading h5 {
    font-size: 0.75em;
  }
  .lend__meter-values {
    width: 64.17px;
    height: 64.17px;
    font-size: 0.94em;
  }

  .lend__meters {
    width: 147px;
    height: auto;
    background-repeat: no-repeat;
    background-size: 77% 81%;
    background-position: 14px 29px;
    h6 {
      font-size: 0.625em;
    }
  }
  .lend__key-points-icon-box {
    img {
      width: 1em;
      height: 1em;
      margin: 12px;
    }
  }

  .lend__each-key-points-box {
    h5 {
      font-size: 0.75em;
      padding-right: 3%;
    }
  }
  .lend__key-points-inner-container {
    padding-right: 25%;
  }
  .lend__key-point-image {
    background-size: 100% 79%;
  }
}

@media (max-width: 748px) {
  .lend {
    background-image: url('../../homeImages/lend-top-background.png');
    background-repeat: no-repeat;
    background-position: 0px -197px;
    background-size: 50% 50%;
  }
  .lend__container {
    background-image: url('../../homeImages/lend-bottom-background.png');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 50% 50%;
  }
  .lend__key-point-image {
    display: none;
  }
  .lend__key-points-container {
    justify-content: flex-start;
    padding-left: 30px;
    margin-top: 2.125em;
  }
  .lend__each-key-points-box {
    margin-bottom: 21px;
  }
  .lend__key-points-inner-container {
    left: 0px;
    width: 99%;
  }
  .lend__deposit-button {
    a {
      width: 80%;
      button {
        width: 100%;
        padding: 0.3em 2em 0.3em 2em;
      }
    }
  }
}
