.access-liquidity {
  position: relative;
  top: 0px;
  width: 100%;
  background: #ffffff;
}

.access-liquidity__container {
  position: relative;
  top: 0px;
  width: 100%;
  padding: 3em 2% 3em 2%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: 'Aktiv Grotesk Corp';
}

.access-liquidity__image img {
  width: 90%;
  height: auto;
}

.access-liquidity__content {
  width: 42%;
  h3 {
    color: #000000;
    font-size: 1.5em;
    font-weight: 600;
  }

  p {
    color: #000000;
    font-weight: 400;
    font-size: 1em;
  }
}

@media (max-width: 748px) {
  .access-liquidity__container {
    padding-top: 1.31em;
    padding-bottom: 1.56em;
  }
  .access-liquidity__content {
    h3 {
      color: #000000;
      font-size: 1em;
    }
    p {
      font-size: 0.58em;
    }
  }
  .access-liquidity__image {
    width: 50%;
    img {
      width: 94%;
    }
  }
}
