//   Importing variable and mixin scss file
@import './MixinAndVariables.scss';

.listed-assets {
  position: relative;
  top: 0px;
  width: 100%;
  background: #121212;
  padding-top: 4.5em;
}

.listed-assets__container {
  position: relative;
  top: 0px;
  width: 100%;
  font-family: 'Aktiv Grotesk Corp';
}

.listed-assets__head {
  @include flexProp;
  position: relative;
  top: 0px;
  width: 100%;

  h1 {
    font-weight: bold;
    font-size: 3em;
    color: #ffffff;
  }
}

.listed-assets__card-container {
  position: relative;
  top: 0px;
  width: 100%;
  padding-top: 1.68em;
}

.listed-assets__box {
  @include flexProp;
  position: relative;
  top: 0px;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 10%;
  padding-right: 10%;
}

.listed-assets__each-card {
  padding: 0.625em 0.937em 0.375em 0.937em;
  background: linear-gradient(180deg, #1f1f1f 0%, rgba(31, 31, 31, 0) 100%);
  filter: drop-shadow(0px 0px 2.5em rgba(0, 0, 0, 0.1));
  border-radius: 16px;
  margin-inline: 0.5em;
  margin-bottom: 1em;
}

.listed-assets__card-head {
  display: flex;
  align-items: center;
  margin-bottom: 1.4em;
}

.listed-assets__cards-logo {
  @include flexProp;
  background: #0e0e0e;
  box-shadow: 0.0625em 0.0625em 0.0625em rgb(255 255 255 / 2%),
    0.375em 0.5em 1.9375em rgb(0 0 0 / 25%), inset 0px -0.25em 0.687em rgb(0 0 0 / 70%),
    inset 0px 0.25em 0.5em rgb(255 255 255 / 18%);
  border-radius: 50%;
  margin-right: 1.125em;

  img {
    position: relative;
    top: 0px;
    z-index: 2;
    width: 2em;
    height: 2em;
    margin: 0.537em;
  }
}
// .listed-assets__aurora-image-cropper {
//   position: relative;
//   top: 0px;
//   width: 2.06em;
//   height: 2.06em;
//   border-radius: 50%;
//   background: #ffffff;
//   margin: 8px;
//   overflow: hidden;
//   img {
//     width: 1.5em;
//     height: 1.5em;
//     margin: 0.21em;
//   }
// }
// .listed-assets__wnear-img:before {
//   position: absolute;
//   content: '';
//   z-index: 1;
//   background: #ffffff;
//   width: 1.75em;
//   height: 1.75em;
//   border-radius: 50%;
// }
// .listed-assets__wnear-img img {
//   width: 1.0625em;
//   height: 1.0625em;
//   margin: 0.8125em;
// }
.listed-assets__card-title {
  h4 {
    font-weight: 500;
    font-size: 1.16em;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
}

.listed-assets__cards-details {
  position: relative;
  display: flex;
  align-items: center;
}

.listed-assets__cards-total-deposit {
  margin-right: 14px;
  h5 {
    font-weight: 300;
    font-size: 0.75em;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
  h4 {
    font-weight: 400;
    font-size: 0.95em;
    letter-spacing: 0.04em;
    color: #67b4f5;
  }
}

.listed-assets__cards-deposited-apy {
  h5 {
    font-weight: 300;
    font-size: 0.75em;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
  h4 {
    font-weight: 400;
    font-size: 0.95em;
    letter-spacing: 0.04em;
    color: #67b4f5;
  }
}

.listed-assets__bottom-para {
  position: relative;
  top: 0px;
  width: 100%;
  margin-top: 1em;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  h2 {
    font-weight: 400;
    font-size: 1.5em;
    color: #ffffff;
  }
}

.listed-assets__start-earning-btn-box {
  @include flexProp;
  position: relative;
  top: 21px;
  width: 100%;

  a {
    @include flexProp;
    button {
      @include ButtonProp;
      padding: 0.6em 2em 0.6em 2em;
      border-radius: 0.5em;
      border: 2px solid transparent;
      margin-bottom: 3.2em;
    }
  }
}

/* Styling for small screen sizes   */
@media (max-width: 931px) {
  .listed-assets__head {
    h1 {
      font-size: 1.75em;
    }
  }
  .listed-assets__each-card {
    padding-top: 9px;
    padding-left: 9px;
    padding-right: 6px;
    padding: 0.562em 0.562em 0.375em 0.375em;
  }
  .listed-assets__cards-logo {
    img {
      width: 1.5275em;
      height: 1.5275em;
      margin: 0.375em;
    }
  }
  .listed-assets__card-head {
    margin-bottom: 0.75em;
  }
  .listed-assets__card-title {
    h4 {
      font-size: 0.875em;
    }
  }
  .listed-assets__cards-total-deposit {
    h5 {
      font-size: 0.51em;
    }
    h4 {
      font-size: 0.625em;
    }
  }
  .listed-assets__cards-deposited-apy {
    h5 {
      font-size: 0.51em;
    }
    h4 {
      font-size: 0.625em;
    }
  }
  .listed-assets__bottom-para h2 {
    font-size: 1.2em;
  }
}

@media (max-width: 748px) {
  .listed-assets__box {
    padding-left: 2%;
    padding-right: 2%;
  }
  .listed-assets {
    padding-top: 2.56em;
  }
  .listed-assets__start-earning-btn-box {
    a {
      width: 80%;
      button {
        width: 100%;
        padding: 0.3em 2em 0.3em 2em;
      }
    }
  }
  // .listed-assets__wnear-img {
  //   img {
  //     width: 1.06em;
  //     height: 1.06em;
  //     margin: 0.65em;
  //   }
  // }
  // .listed-assets__aurora-image-cropper {
  //   margin: 5px;
  //   width: 1.7em;
  //   height: 1.7em;
  //   img {
  //     width: 1.2em;
  //     height: 1.2em;
  //     margin: 0.25em;
  //   }
  // }
}
