//   Importing variable and mixin scss file
@import './MixinAndVariables.scss';

.footer {
  position: relative;
  top: 0px;
  width: 100%;
  background: #121212;
}

.footer__container {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  padding-top: 4.5em;
  padding-bottom: 2.5em;
  font-family: 'Aktiv Grotesk Corp';
}

.footer__head {
  @include flexProp;
  position: relative;
  top: 0px;
  width: 100%;
  flex-direction: column;

  h2 {
    width: 50%;
    text-align: center;
    font-weight: 600;
    font-size: 3em;
    line-height: 1.3;
    background: linear-gradient(91.41deg, #c763f7 8.98%, #67b4f5 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.footer__social-icon-box {
  @include flexProp;
  position: relative;
  top: 0px;
  margin-top: 1.81em;
  span {
    @include flexProp;
    flex-direction: column;
    cursor: pointer;
    a {
      img {
        width: 2.625em;
        height: auto;
      }
      p {
        margin-top: 0.5em;
        font-weight: 300;
        font-size: 0.58em;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}

.footer__twitter-icon {
  margin-right: 42px;
}

.footer__bottom-box {
  position: relative;
  top: 0px;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 6.37em;
}

.footer__bottom-inner-box {
  position: relative;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer__brainiac-logo-box {
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    width: 7.12em;
    height: 5.25em;
    image-rendering: optimizeSpeed;
    image-rendering: -webkit-optimize-contrast;
  }
}

.footer__logo-bottom-social-icons {
  display: flex;
  justify-content: space-between;
  a {
    margin-right: 26px;
    cursor: pointer;
    img {
      width: 1.5em;
      height: auto;
    }
    img:nth-last-child(1) {
      margin-right: 0px;
    }
  }
}

.footer__quick-links-box {
  width: 29%;
  position: relative;
  top: 0px;
  margin-bottom: 4.625em;
}

.footer__quick-links-inner-box {
  position: relative;
  top: 0px;
  width: 100%;
  margin: 0px !important;
  display: flex;
  justify-content: space-between;
}

.footer__quick-link-contact--each-quick-links {
  ul {
    h4 {
      font-weight: 600;
      font-size: 1em;
      margin-bottom: 2em;
      color: #ffffff;
    }
    a {
      li {
        font-weight: 400;
        font-size: 0.75em;
        color: #ffffff;
        list-style: none;
        cursor: pointer;
        margin-bottom: 1.5em;
      }
      li:hover {
        text-shadow: 0 0 0.6em rgb(0 0 0 / 40%), 0 0 0.7em rgb(0 0 0 / 40%),
          0 0 0.8em rgb(155 24 239 / 58%), 0 0 0.9em rgb(155 24 239 / 58%),
          0 0 1em rgb(155 24 239 / 58%), 0 0 1.5em rgb(155 24 239 / 58%),
          0 0 2em rgb(155 24 239 / 58%);
      }
    }
  }
}

.footer__sign-up-container {
  position: relative;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 9em;
}

.footer__sign-up-box {
  width: 25%;
  color: #ffffff;
  h3 {
    // font-family: 'SF Pro Text';
    font-weight: 700;
    font-size: 0.7em;
    line-height: 1.5;
    text-transform: uppercase;
    color: #ffffff;
  }
  h4 {
    // font-family: 'SF Pro Text';
    font-weight: 300;
    font-size: 0.7em;
    line-height: 1.5;
    color: #ffffff;
  }
}
.footer__input-form {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.footer__legal-disclaimer {
  position: relative;
  top: 0px;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 2.687em;
  color: #ffffff;

  h3 {
    font-weight: 400;
    font-size: 0.75em;
    margin-bottom: 0.4562em;
    color: #ffffff;
  }

  p {
    font-weight: 400;
    font-size: 0.6em;
    color: #ffffff;
  }
}

.footer__input {
  width: 15em;
  height: 2.7em;
  // font-family: 'SF Pro Text';
  font-weight: 400;
  font-size: 0.7944em;
  line-height: 1.5;
  padding-left: 12px;
  padding-right: 10%;
  border: none;
  outline: none;
  color: #000000;
  background: #dedede;
  border-radius: 0.581em;
}
.footer__submit-button {
  @include ButtonProp;
  position: relative;
  left: -5%;
  padding: 0.28em 2.3em 0.28em 2.3em;
  border-radius: 0.581em;
  border: 0.125em solid transparent;
}

@media (max-width: 931px) {
  .footer__sign-up-container {
    width: 100%;
    padding: 0px;
    justify-content: center;
  }
  .footer__head h2 {
    font-size: 1.5em;
  }
  .footer__sign-up-box {
    @include flexProp;
    width: 50%;
    text-align: center;
    flex-direction: column;
  }
  .footer__input {
    width: 80%;
    height: 2.8em;
  }
  .footer__submit-button {
    padding: 0.4em 3em 0.4em 3em;
  }
  .footer__sign-up-box h3 {
    font-size: 1.1em;
  }
  .footer__legal-disclaimer h3 {
    font-size: 1em;
  }

  .footer__social-icon-box {
    span {
      a {
        img {
          width: 1.75em;
        }
        p {
          display: none;
        }
      }
    }
  }
  .footer__quick-link-contact--each-quick-links {
    ul {
      margin-right: 24px;
      h4 {
        font-size: 0.8em;
      }
      a {
        li {
          font-size: 0.7em;
        }
      }
    }
  }
  .footer__sign-up-box {
    width: 76%;
  }
}

@media (max-width: 748px) {
  .footer__container {
    padding-top: 2.3125em;
  }
  .footer__bottom-box {
    margin-top: 2.5em;
  }
  .footer__quick-links-box {
    margin-bottom: 2.5em;
  }
  .footer__submit-button {
    padding: 0.3em 2.5em 0.3em 2.5em;
  }
  .footer__input {
    height: 2.7em;
  }
}
@media (max-width: 692px) {
  .footer__head h2 {
    width: 57%;
  }
  .footer__brainiac-logo-box > img {
    width: 5.875em;
    height: 4.4375em;
  }
  .footer__logo-bottom-social-icons {
    a {
      img {
        width: 1em;
        height: auto;
        image-rendering: optimizeSpeed;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
  .footer__quick-links-box {
    margin-right: 45px;
  }
  .footer__quick-link-resource {
    ul {
      margin-right: 4px;
    }
  }

  .footer__quick-link-contact--each-quick-links {
    ul {
      h4 {
        font-size: 0.5625em;
      }
      a {
        li {
          font-size: 0.48em;
        }
      }
    }
  }

  .footer__legal-disclaimer {
    h3 {
      font-size: 0.625em;
    }

    p {
      font-size: 0.45em;
    }
  }
}
