//   Importing variable and mixin scss file
@import './MixinAndVariables.scss';

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.navbar {
  position: relative;
  top: 0px;
  width: 100%;
  height: 5.8em;
  background: #181818;
  box-shadow: 0px 0.25em 9.562em rgba(0, 0, 0, 0.37);
}

.navbar__container {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  font-family: 'Aktiv Grotesk Corp';
}

.navbar__logo {
  position: relative;
  left: 4.4%;
  display: flex;
  width: fit-content;
  align-items: center;
  height: 100%;
  .navbar__logo-background img {
    width: 3.75em;
    height: auto;
    image-rendering: optimizeSpeed;
    image-rendering: -webkit-optimize-contrast;
  }

  h4 {
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 1.6em;
    color: #ffffff;
  }
}

.navbar__logo-background {
  @include flexProp;
  width: 4em;
  height: 3.7em;
  background-color: #000000;
  border-radius: 15px;
  margin-right: 9.3px;
}
.navbar__links-and-button-box {
  position: absolute;
  top: 0px;
  right: 57px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar__links {
  @include flexProp;
  position: absolute;
  top: 0px;
  right: 380%;
  height: 100%;
  white-space: nowrap;
  color: #ffffff;

  ul {
    display: flex;
    margin-bottom: 0px;

    a {
      margin-right: 20%;
      text-decoration: none;
      li {
        list-style: none;
        color: #ffffff;
        font-weight: 400;
        font-size: 1em;
      }
      li:hover {
        text-shadow: 0 0 0.6em rgb(0 0 0 / 40%), 0 0 0.7em rgb(0 0 0 / 40%),
          0 0 0.8em rgb(155 24 239 / 58%), 0 0 0.9em rgb(155 24 239 / 58%),
          0 0 1em rgb(155 24 239 / 58%), 0 0 1.5em rgb(155 24 239 / 58%),
          0 0 2em rgb(155 24 239 / 58%);
      }
    }
  }
}

.navbar__launch-btn-box {
  @include flexProp;
  position: relative;
  top: 0px;
  height: 100%;
}

.navbar__launch-btn {
  font-weight: 400;
  font-size: 1em;
  background: linear-gradient(#c763f7, #c763f7) padding-box,
    linear-gradient(to right, #682293, #df717f) border-box;
  box-shadow: 0px 0px 0.625em rgb(0 0 0 / 40%), 0px 0px 2.5em rgb(155 24 239 / 58%);
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.5em;
  padding: 0.4em 1.1em 0.4em 1.1em;
  white-space: nowrap;
  border: 0.125em solid transparent;
}

/* Hamburger icon styling  */
#navbar__hamburger-show-side-bar-icon {
  position: absolute;
  top: 44%;
  right: 32px;
  z-index: 1;
  width: 2.18em;
  z-index: 7;
  cursor: pointer;
}

.navbar__hamburger-icon-lines {
  width: 69%;
  height: 0.1em;
  margin-bottom: 4px;
  background: #ffffff;
}

// Sidebar Styling
.navbar__sidebar-logo-box {
  display: none;
}

.navbar__sidebar-social-icons {
  display: none;
}

.navbar__sidebar-logo-title {
  display: none;
}

#navbar__hamburger-show-side-bar-icon {
  display: none;
}
#navbar__hamburger-hide-side-bar-icon {
  display: none;
}
#navbar__hamburger-short-line {
  width: 45%;
}

/* Styling for small screen sizes   */

@media (max-width: 1010px) {
  .navbar__links {
    right: 176%;
    ul {
      a {
        margin-right: 7%;
      }
    }
  }
}
@media (max-width: 931px) {
  .navbar {
    position: absolute;
    z-index: 3;
    background: transparent;
    box-shadow: none;
    height: 9.8em;
  }
  .navbar__logo {
    .navbar__logo-background {
      background: transparent;
      img {
        width: 4.75em;
      }
    }
  }
  #navbar__hamburger-show-side-bar-icon {
    display: inline-block;
  }
  #navbar__hamburger-hide-side-bar-icon {
    position: absolute;
    top: 1.625em;
    right: 2em;
    z-index: 1;
    width: 2.18em;
    z-index: 7;
    cursor: pointer;
    display: none;
    transform: rotate(180deg);
  }
  .navbar__links-and-button-box {
    top: 0px;
    right: 0px;
    width: 39%;
    min-width: 15.3em;
    height: fit-content;
    display: none;
    z-index: 1;
    flex-direction: column;

    background: linear-gradient(
      173.11deg,
      #101010 -7.54%,
      rgba(37, 37, 37, 0.81) 55.36%,
      rgba(52, 52, 52, 0.7) 96.58%
    );
    backdrop-filter: blur(44px);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    padding-top: 102px;
    box-sizing: content-box;
  }
  .navbar__links {
    position: relative;
    right: 0px;
    height: unset;
    width: 100%;
    justify-content: flex-end;
    margin-right: 69px;
    ul {
      flex-direction: column;
      a {
        margin-right: 0px;
        margin-bottom: 37px;
        text-align: right;
        li {
          font-weight: 400;
          font-size: 1.06em;
          letter-spacing: 0.04em;
        }
      }
    }
  }

  // Sidebar styling for smaller screen
  .navbar__sidebar-logo-box {
    display: inline-block;
    position: relative;
    margin-top: 12.56em;
    background: #141414;
    border-radius: 14.6998px;

    img {
      margin-top: 8px;
      margin-bottom: 8px;
      margin-left: 2px;
      margin-right: 2px;
      width: 4.56em;
      height: auto;
      image-rendering: optimizeSpeed;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  .navbar__sidebar-logo-title {
    display: inline-block;
    position: relative;
    top: 0.625em;
    h5 {
      font-weight: 400;
      font-size: 0.75em;
      text-align: center;
      letter-spacing: 0.04em;
      color: #ffffff;
    }
  }
  .navbar__launch-btn-box {
    position: relative;
    top: 2.31em;
    width: 100%;
  }
  .navbar__sidebar-social-icons {
    display: inline-block;
  }

  .navbar__sidebar-social-icons {
    position: relative;
    margin-top: 3.8em;
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2.312em;
  }

  .sidebar-social-icons {
    a {
      img {
        width: 1.68em;
        height: auto;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 550px) {
  .navbar__logo {
    h4 {
      font-size: 1.2em;
    }
  }
}
