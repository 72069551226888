//   Importing variable and mixin scss file
@import './MixinAndVariables.scss';

.vote {
  position: relative;
  top: 0px;
  width: 100%;
  background: #121212;
}

.vote__container {
  position: relative;
  top: 0px;
  width: 100%;
  padding-top: 3.5em;
  padding-bottom: 5.2em;
  font-family: 'Aktiv Grotesk Corp';
}
.launch-info-container{
  font-size: small;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.vote__head {
  position: relative;
  top: 0px;
  width: 100%;
  text-align: center;
  margin-bottom: 1.25em;
}

.vote__heading {
  h2 {
    font-size: 3em;
  }
}
.vote__details-container {
  position: relative;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.vote__token-distribution-container {
  position: relative;
  top: 0px;
  width: 64%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.vote__token-distribution-card-container {
  position: relative;
  top: 0px;
}

.vote__token-distribution-card {
  position: relative;
  top: 0px;
  z-index: 4;
  width: 15em;
  display: inline-block;
  background: linear-gradient(148.38deg, #000000 0%, #131313 45.46%, #303030 100%);
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.18);
  border-radius: 1em;
}

.vote__card-cyan-square {
  position: absolute;
  z-index: 3;
  top: 81%;
  left: -3.18em;
  width: 5.25em;
  height: 5.25em;
  background: radial-gradient(74.41% 74.41% at 50% 50%, #40ebff 0%, #37a5b1 48.96%, #1a5b63 100%);
  border-radius: 1.28em;
  transform: rotate(38.88deg);
  border: 0.125em solid #89e1ea;
}
.vote__card-black-square {
  position: absolute;
  z-index: 4;
  top: 26%;
  left: 13.8em;
  width: 5.25em;
  height: 5.25em;
  background: radial-gradient(
    74.41% 74.41% at 50% 50%,
    #141313 0%,
    #151515 18.75%,
    #111010 47.92%,
    #000000 100%
  );
  box-shadow: 0px 4px 28px rgb(0 0 0 / 39%);
  border-radius: 1.28em;
  border: 0.25em solid #00000040;
}

.vote__card-vote-display-square {
  @include flexProp;
  position: absolute;
  z-index: 4;
  top: 41%;
  left: 16.6em;
  width: 5.25em;
  height: 5.25em;
  background: radial-gradient(
    74.41% 74.41% at 50% 50%,
    #141313 0%,
    #151515 18.75%,
    #111010 47.92%,
    #000000 100%
  );
  box-shadow: 0px 0.25em 1.75em rgb(0 0 0 / 39%);
  border-radius: 1.28em;
  border: 0.25em solid #00000040;
  flex-direction: column;
}
.vote__card-purple-square {
  position: absolute;
  z-index: 2;
  top: 31%;
  left: 17em;
  width: 4.125em;
  height: 4.125em;
  background: radial-gradient(74.41% 74.41% at 50% 50%, #c763f7 0%, #560e79 100%);
  border-radius: 1.28em;
  transform: rotate(10.44deg);
  border: 2px solid #c763f7;
}

.vote__purple-blur-spot {
  position: absolute;
  z-index: 2;
  top: 9%;
  left: -1.187em;
  width: 4.125em;
  height: 4.125em;
  background: radial-gradient(74.41% 74.41% at 50% 50%, #c763f7 0%, #560e79 100%);
  filter: blur(2.87em);
  border-radius: 1.28em;
  transform: rotate(10.44deg);
}
.vote__purple-blur-square {
  position: absolute;
  z-index: 2;
  top: 47%;
  left: 13.187em;
  width: 4.125em;
  height: 4.125em;
  background: radial-gradient(74.41% 74.41% at 50% 50%, #c763f7 0%, #560e79 100%);
  filter: blur(1.5em);
  border-radius: 1.28em;
  transform: rotate(10.44deg);
}
.vote__cyan-blur-spot {
  position: absolute;
  z-index: 2;
  top: 80%;
  left: 12.93em;
  width: 4.125em;
  height: 4.125em;
  background: radial-gradient(74.41% 74.41% at 50% 50%, #40ebff 0%, #37a5b1 48.96%, #1a5b63 100%);
  filter: blur(2.56em);
  border-radius: 1.28em;
  transform: rotate(38.88deg);
}
.vote__card-vote-display-square {
  p:nth-child(1) {
    font-weight: bold;
    font-size: 1.5em;
    color: #ffffff;
  }

  p:nth-child(2) {
    font-weight: 300;
    font-size: 0.7em;
    color: #ffffff;
  }
}

.vote__token-distribution-head {
  @include flexProp;
  position: relative;
  top: 0px;
  z-index: 4;
  width: 100%;
  padding-top: 2.3em;
  flex-direction: column;
  text-align: center;
  border-bottom: 0.162em solid #000000;

  h3 {
    font-weight: bold;
    width: 88%;
    font-size: 1.16em;
    line-height: 1.3;
  }

  p {
    font-weight: 400;
    font-size: 0.58em;
    text-align: center;
    text-decoration-line: underline;
    color: #40ebff;
    margin-bottom: 1.6em;
  }
}

.vote__token-distribution-vote-count-container {
  position: relative;
  top: 0px;
  width: 100%;
  display: flex;
}

.vote__token-distribution-yes-count {
  position: relative;
  top: 0px;
  width: 50%;
  padding-top: 1.31em;
  padding-bottom: 1.56em;
  text-align: center;
  border-right: 0.162em solid #000000;
  border-bottom: 0.162em solid #000000;

  h3 {
    font-weight: 400;
    font-size: 1em;
    color: #ffffff;
  }

  h5 {
    font-weight: 400;
    font-size: 0.59em;
    color: #ffffff;
  }
}

.vote__token-distribution-no-count {
  position: relative;
  top: 0px;
  width: 50%;
  padding-top: 1.31em;
  padding-bottom: 1.56em;
  text-align: center;
  border-bottom: 0.162em solid #000000;

  h3 {
    font-weight: 400;
    font-size: 1em;
    color: #ffffff;
  }

  h5 {
    font-weight: 400;
    font-size: 0.59em;
    color: #ffffff;
  }
}

.vote__token-distribution-vote-button {
  @include flexProp;
  position: relative;
  top: 0px;
  width: 100%;
  margin-top: 2.25em;
  margin-bottom: 2.25em;
  a {
    button {
      @include ButtonProp;
      padding: 0.5em 3.8em 0.5em 3.8em;
      border-radius: 0.413em;
      border: 0.125em solid transparent;
      font-weight: 400;
      font-size: 0.71em;
      color: #ffffff;
    }
  }
}

.vote__info-container {
  position: relative;
  top: 0px;
  margin-left: 7.875em;
  width: 40%;

  h2 {
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1.3;
    margin-bottom: 1.75em;
    color: #ffffff;
  }
  p {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.3;
    color: #ffffff;
    width: 84%;
  }
  button {
    @include ButtonProp;
    padding: 0.5em 3em 0.5em 3em;
    margin-top: 1.18em;
    border-radius: 0.413em;
    border: 0.125em solid transparent;
    font-weight: 400;
    font-size: 1em;
  }
}

/* Styling for small screen sizes   */
@media (max-width: 931px) {
  .vote__heading {
    h2 {
      font-size: 2em;
    }
  }

  .vote__token-distribution-head {
    h3 {
      width: 45%;
      font-size: 0.8em;
    }
    p {
      font-size: 0.5em;
    }
  }

  .vote__token-distribution-card {
    width: 14em;
  }

  .vote__card-cyan-square {
    top: 88%;
    left: -1.5em;
    width: 3.5em;
    height: 3.37em;
    border-radius: 0.6625em;
    border: 0.0625em solid #89e1ea;
  }

  .vote__card-black-square {
    top: 26%;
    left: 12.25em;
    width: 3.812em;
    height: 3.812em;
  }

  .vote__card-purple-square {
    top: 31%;
    left: 14.43em;
    width: 2.81em;
    height: 2.81em;
    border-radius: 0.6625em;
  }

  .vote__card-vote-display-square {
    width: 3.81em;
    height: 3.81em;
    top: 38%;
    left: 15.06em;
    p:nth-child(1) {
      font-size: 1em;
    }
    p:nth-child(2) {
      font-size: 0.7em;
    }
  }

  .vote__token-distribution-yes-count {
    h3 {
      font-size: 0.75em;
    }
    h5 {
      font-size: 0.48em;
    }
  }

  .vote__token-distribution-no-count {
    h3 {
      font-size: 0.75em;
    }
    h5 {
      font-size: 0.48em;
    }
  }

  .vote__info-container {
    p {
      font-size: 0.75em;
      width: 100%;
    }
    h2 {
      font-size: 1.05em;
    }
    a {
      button {
        font-size: 0.8em;
        padding: 0.25em 1.5em 0.25em 1.5em;
      }
    }
  }
}

@media (max-width: 748px) {
  .vote {
    padding-top: 2.125em;
  }
  .vote__container {
    padding-top: 1em;
  }

  .vote__token-distribution-container {
    width: 91%;
  }

  .vote__token-distribution-card {
    width: 10.62em;
  }

  .vote__token-distribution-head {
    padding-top: 1.1875em;
    h3 {
      width: 34%;
      font-size: 0.63em;
    }
    p {
      font-size: 0.4em;
    }
  }

  .vote__token-distribution-yes-count {
    padding-top: 0.625em;
    padding-bottom: 0.625em;
  }

  .vote__token-distribution-no-count {
    padding-top: 0.625em;
    padding-bottom: 0.625em;
  }

  .vote__token-distribution-vote-button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .vote__card-cyan-square {
    top: 83%;
    left: -13px;
    width: 3em;
    height: 3em;
  }

  .vote__card-black-square {
    left: 156px;
    width: 3em;
    height: 3em;
    border-radius: 10px;
  }
  .vote__purple-blur-square {
    top: 46%;
    left: 9.18em;
    width: 3.125em;
    height: 3.125em;
  }

  .vote__card-vote-display-square {
    width: 3em;
    height: 3em;
    left: 184px;
    border-radius: 10px;
  }

  .vote__card-purple-square {
    left: 184px;
    width: 2.12em;
    height: 2.12em;
  }

  .vote__card-vote-display-square {
    p:nth-child(1) {
      font-size: 0.78em;
    }
    p:nth-child(2) {
      font-size: 0.64em;
    }
  }

  .vote__token-distribution-vote-button {
    a {
      button {
        padding: 0.3em 2em 0.3em 2em;
      }
    }
  }

  .vote__info-container {
    margin-left: 92px;
    a {
      button {
        padding: 0.38em 2em 0.38em 2em;
      }
    }
    p {
      font-size: 0.57em;
    }
    h2 {
      font-size: 0.8125em;
    }
  }

  .vote__cyan-blur-spot {
    left: 9.06em;
  }
}

@media (max-width: 482px) {
  .vote__info-container {
    margin-left: 72px;
  }
  .vote__card-cyan-square {
    top: 82%;
    left: -6px;
  }
  .vote__card-black-square {
    left: 150px;
  }
  .vote__card-vote-display-square {
    left: 180px;
  }
  .vote__card-purple-square {
    left: 185px;
  }
}
