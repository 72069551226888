.hero {
  position: relative;
  top: 0em;
  left: 0em;
  width: 100%;
  background-color: #121212;
  overflow: hidden;
  background-image: url('../../homeImages/plexus-lines.png');
  background-position: 100% 0px;
  background-size: 71% 132%;
  background-repeat: no-repeat;
  image-rendering: optimizeSpeed;
  image-rendering: -webkit-optimize-contrast;
}

.hero__container {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 2.8125em;
  overflow: hidden;
  font-family: 'Aktiv Grotesk Corp';
}

.hero__background {
  position: absolute;
  top: -18%;
  right: 0px;
  width: 74%;
  height: auto;
  overflow: hidden;
}

.hero__info {
  position: relative;
  padding-left: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hero__intro-head {
  width: 25%;

  h1 {
    font-weight: bold;
    font-size: 3em;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 2%;
  }
  h3 {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
  button {
    margin-top: 29px;
    font-size: 1em;
    padding: 0.4em 1.1em 0.4em 1.1em;
    background: linear-gradient(#c763f7, #c763f7) padding-box,
      linear-gradient(to right, #682293, #df717f) border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4), 0px 0px 40px rgba(155, 24, 239, 0.58);
    color: #ffffff;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 0.5em;
    border: 2px solid transparent;
  }
}

.hero__image {
  img {
    width: 78%;
    height: auto;
  }
}

.hero__powered-by {
  position: relative;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3.125em;
  padding-bottom: 2.312em;
}

.hero__bottom-para {
  font-weight: 300;
  font-size: 1em;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-bottom: 2.437em;
}

.hero__powered-by-logo {
  margin-bottom: 1.2875em;
  img {
    width: 6em;
    height: auto;
  }
}

/* Styling for small screen sizes   */
@media (min-width: 1500px) {
  .hero__background {
    max-width: 60%;
  }
}

@media (min-width: 1831px) {
  .hero__image {
    margin-top: 2%;
    margin-right: 7%;
  }
}
@media (max-width: 1090px) {
  .hero__image {
    position: relative;
    right: 53px;
    img {
      width: 62%;
    }
  }
}

@media (max-width: 1088px) {
  .hero__image {
    display: flex;
    justify-content: flex-end;
    right: 10%;
    img {
      width: 65%;
    }
  }
  .hero__background {
    position: absolute;
    top: -1%;
    width: 59%;
    height: auto;
  }
}

@media (max-width: 931px) {
  .hero {
    z-index: 2;
    padding-top: 5.8em;
    background-position: -98% 10%;
    background-size: 113% 177%;
  }
  .hero__intro-head {
    width: 34%;
    h1 {
      font-size: 2em;
    }
    h3 {
      font-size: 0.75em;
      font-weight: lighter;
    }
    button {
      padding: 0.4em 1.1em 0.4em 1.1em;
      font-size: 0.8em;
    }
  }
  .hero__info {
    padding-left: 5%;
  }
  .hero__image {
    right: 5%;
    img {
      width: 50%;
      min-width: 15em;
    }
  }

  .hero__bottom-para {
    margin-top: 1.125em;
    color: #000000;
    font-size: 0.75em;
  }
  .hero__powered-by {
    background-color: #ffffff;
    color: #000000;
    padding-bottom: 20px;
  }
  .hero__powered-by-logo {
    img {
      width: 2.125em;
      height: auto;
    }
  }
  .hero__powered-by-heading {
    h1 {
      font-size: 1.37em;
      white-space: nowrap;
    }
  }
}

@media (max-width: 748px) {
  .hero__intro-head {
    min-width: 151px;
    width: 41%;
    h1 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 0.75em;
    }
  }

  .hero__background {
    top: 1%;
    right: 26px;
  }
}

@media (max-width: 525px) {
  .hero__background {
    top: 13%;
    right: 15%;
  }
}

@media (max-width: 448px) {
  .hero__background {
    top: 15%;
    right: 18px;
  }
}

@media (max-width: 448px) {
  .hero__image {
    margin-top: -4em;
    img {
      min-width: 12em;
    }
  }
  .hero__background {
    top: 15%;
    right: 14%;
  }
}

@media (max-width: 383px) {
  .hero__image {
    img {
      min-width: 10em;
    }
  }
}
