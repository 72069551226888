//Defining mixin
//commonly used flex properties
@mixin flexProp {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin ButtonProp {
  white-space: nowrap;
  box-shadow: 0px 0px 0.41em rgb(0 0 0 / 40%), 0px 0px 1.66em rgb(155 24 239 / 58%);
  cursor: pointer;
  outline: none;
  color: #ffffff;
  background: linear-gradient(#c763f7, #c763f7) padding-box,
    linear-gradient(to right, #682293, #df717f) border-box;
}
