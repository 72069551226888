@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('AktivGroteskCorp-Medium.eot');
  src: local('AktivGroteskCorp-Medium'),
    url('AktivGroteskCorp-Medium.eot?#iefix') format('embedded-opentype'),
    url('AktivGroteskCorp-Medium.woff2') format('woff2'),
    url('AktivGroteskCorp-Medium.woff') format('woff'),
    url('AktivGroteskCorp-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('AktivGroteskCorp-Bold.eot');
  src: local('AktivGroteskCorp-Bold'),
    url('AktivGroteskCorp-Bold.eot?#iefix') format('embedded-opentype'),
    url('AktivGroteskCorp-Bold.woff2') format('woff2'),
    url('AktivGroteskCorp-Bold.woff') format('woff'),
    url('AktivGroteskCorp-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('AktivGroteskCorp-Italic.eot');
  src: local('AktivGroteskCorp-Italic'),
    url('AktivGroteskCorp-Italic.eot?#iefix') format('embedded-opentype'),
    url('AktivGroteskCorp-Italic.woff2') format('woff2'),
    url('AktivGroteskCorp-Italic.woff') format('woff'),
    url('AktivGroteskCorp-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('AktivGroteskCorp-LightItalic.eot');
  src: local('AktivGroteskCorp-LightItalic'),
    url('AktivGroteskCorp-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('AktivGroteskCorp-LightItalic.woff2') format('woff2'),
    url('AktivGroteskCorp-LightItalic.woff') format('woff'),
    url('AktivGroteskCorp-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('AktivGroteskCorp-MediumItalic.eot');
  src: local('AktivGroteskCorp-MediumItalic'),
    url('AktivGroteskCorp-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('AktivGroteskCorp-MediumItalic.woff2') format('woff2'),
    url('AktivGroteskCorp-MediumItalic.woff') format('woff'),
    url('AktivGroteskCorp-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('AktivGroteskCorp-Light.eot');
  src: local('AktivGroteskCorp-Light'),
    url('AktivGroteskCorp-Light.eot?#iefix') format('embedded-opentype'),
    url('AktivGroteskCorp-Light.woff2') format('woff2'),
    url('AktivGroteskCorp-Light.woff') format('woff'),
    url('AktivGroteskCorp-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('AktivGroteskCorp-Regular.eot');
  src: local('AktivGroteskCorp-Regular'),
    url('AktivGroteskCorp-Regular.eot?#iefix') format('embedded-opentype'),
    url('AktivGroteskCorp-Regular.woff2') format('woff2'),
    url('AktivGroteskCorp-Regular.woff') format('woff'),
    url('AktivGroteskCorp-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aktiv Grotesk Corp';
  src: url('AktivGroteskCorp-BoldItalic.eot');
  src: local('AktivGroteskCorp-BoldItalic'),
    url('AktivGroteskCorp-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('AktivGroteskCorp-BoldItalic.woff2') format('woff2'),
    url('AktivGroteskCorp-BoldItalic.woff') format('woff'),
    url('AktivGroteskCorp-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
