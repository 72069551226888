//   Importing variable and mixin scss file
@import './MixinAndVariables.scss';

.borrow {
  position: relative;
  top: 0px;
  width: 100%;
  background: #121212;
  padding-top: 88px;
}

.borrow__container {
  position: relative;
  top: 0px;
  overflow: hidden;
  font-family: 'Aktiv Grotesk Corp';
}

.borrow__head {
  @include flexProp;
  position: relative;
  top: 0px;
  width: 100%;
}

.borrow__heading {
  width: 45%;
  text-align: center;
  color: #ffffff;
  h1 {
    font-weight: 600;
    font-size: 3em;
  }

  h4 {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.04em;
  }
}

.borrow__key-points-container {
  position: relative;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5.5em;
}

.borrow__key-points-inner-container {
  position: relative;
  top: 0px;
  left: 15%;
  width: 23%;
  min-width: 300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.each-key-point-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4em;
  min-width: 20em;

  h5 {
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #ffffff;
  }
}

.borrow__key-points-icon-box {
  background: #0e0e0e;
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.02), 6px 8px 31px rgba(0, 0, 0, 0.25),
    inset 0px -4px 11px rgba(0, 0, 0, 0.7), inset 0px 4px 8px rgba(255, 255, 255, 0.18);
  border-radius: 50%;
  margin-right: 10px;
  img {
    width: 1.31em;
    height: 1.31em;
    margin: 0.93em;
  }
}

.borrow__key-point-image {
  position: relative;
  top: 0px;
  z-index: 1;
  width: 46%;
  min-width: 35em;
  height: 25.6em;
}

.borrow__side-images {
  position: relative;
  top: 0px;
  left: 0px;
  height: 100%;
}

.borrow__web-background {
  position: absolute;
  top: -50px;
  left: 4%;
  width: 85%;
  height: auto;
}
.borrow__side-ring {
  position: absolute;
  top: 35px;
  left: 16%;
  width: 53%;
  height: auto;
  animation: MoveUpDown 2s alternate infinite;
}

@keyframes MoveUpDown {
  0% {
    transform: translateY(0);
    transform: rotate3d(5px 8px 8px 45deg);
  }
  100% {
    transform: translateY(-10px);
    transform: rotate3d(5px 8px 8px 85deg);
  }
}

.borrow__long-curl {
  position: absolute;
  top: -144px;
  left: 31%;
  width: 53%;
  height: auto;
  animation: MoveUpDown 2.5s alternate infinite;
}
.borrow__c-shape {
  position: absolute;
  top: 50px;
  left: 18%;
  width: 30%;
  height: auto;
  animation: MoveUpDown 3s alternate infinite;
}
.borrow__cone {
  position: absolute;
  top: 263px;
  left: 43%;
  width: 29%;
  height: auto;
  animation: MoveUpDown 2.8s alternate infinite;
}

.borrow__borrow-now-btn {
  @include flexProp;
  position: relative;
  top: 0px;
  width: 100%;
  a {
    @include flexProp;
    button {
      @include ButtonProp;
      padding: 0.6em 2em 0.6em 2em;
      border-radius: 0.5em;
      border: 0.125em solid transparent;
    }
    margin-bottom: 2.562em;
  }
}

/* Styling for small screen sizes   */
@media (max-width: 980px) {
  .borrow__side-images {
    top: -10%;
    left: 24%;
    width: 89%;
  }
}
@media (max-width: 931px) {
  .borrow__key-points-inner-container {
    left: 10%;
  }
  .borrow__side-images {
    left: 17%;
  }
  .borrow__heading {
    h1 {
      font-size: 1.75em;
    }
  }
  .borrow__heading {
    h4 {
      font-size: 0.75em;
    }
  }

  .borrow__key-points-icon-box {
    img {
      width: 1em;
      height: 1em;
      margin: 0.75em;
    }
  }
  .each-key-point-box {
    h5 {
      font-size: 0.75em;
    }
  }

  .borrow__side-ring {
    position: absolute;
    top: 29px;
    left: 16%;
    width: 262px;
    height: 263px;
  }
  .borrow__web-background {
    height: 446px;
  }
  .borrow__long-curl {
    top: -89px;
    left: 27%;
    width: 330px;
    height: 257px;
  }
  .borrow__c-shape {
    top: 10px;
    left: 27%;
    width: 24%;
    height: 201px;
  }
  .borrow__cone {
    top: 222px;
    left: 43%;
    width: 34%;
    height: auto;
  }
}

@media (max-width: 835px) {
  .borrow__side-images {
    width: 72%;
  }
}
@media (max-width: 789px) {
  .borrow__side-images {
    left: 18%;
    width: 59%;
  }
}
@media (max-width: 748px) {
  .borrow {
    padding-top: 28px;
  }
  .borrow__key-point-image {
    display: none;
  }
  .borrow__key-points-container {
    padding-left: 30px;
    padding-right: 14px;
    padding-top: 34px;
  }
  .borrow__key-points-inner-container {
    left: 0px;
    width: unset;
  }
  .each-key-point-box {
    margin-bottom: 21px;
  }
  .borrow__heading {
    width: 75%;
  }
  .borrow__borrow-now-btn {
    a {
      width: 80%;
      button {
        width: 100%;
        padding: 0.3em 2em 0.3em 2em;
      }
    }
  }
}

@media (min-width: 1575px) {
  .borrow__cone {
    top: 350px;
  }
}
